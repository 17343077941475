@import url("https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
  font-family: "Gantari", sans-serif;
  font-size: 16px;
}

.outline {
  outline: 1px red dashed;
  &-all {
    &,
    * {
      @extend .outline;
      outline-color: cyan;
    }
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

/* Flex Container Classes */
.d-flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-baseline {
  align-items: baseline;
}

/* Flex Item Classes */
.flex-grow-1 {
  flex-grow: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

/* Justify Content Classes */
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}

/* Align Items Classes */
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-baseline {
  align-items: baseline;
}

/* Flex Item Ordering Classes */
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}

/* Flex Item Margin Classes */
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* Flex Item Self-Alignment Classes */
.align-self-start {
  align-self: flex-start;
}
.align-self-center {
  align-self: center;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-stretch {
  align-self: stretch;
}

/* Add more classes as needed */

/* Width Classes */
.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}
.w-auto {
  width: auto;
}

.fw-normal {
  font-weight: normal !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}

.highlighted {
  animation: smoothBlink 0.9s cubic-bezier(0.55, 0.055, 0.675, 0.19) both;
}
// // css scrollbar
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #eff1f7;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #eff1f7;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #cac5cd;
}

// Misc
#root {
  background-color: #f6f6f6;
}
.MuiDataGrid-panel[role="tooltip"] {
  animation: fade-in 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.15s both;

  .MuiPaper-root {
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  }

  &.rolesSearchBarFilter {
    position: absolute !important;
    top: 100px !important;
    left: auto !important;
    right: 106px !important;
    transform: unset !important;
  }
}

.pac-logo:after {
  content: none;
}

.pac-container {
  z-index: 2000; // INFO: Google places api autocomplete z-index is set hight to put it on top even on in a dialog modal
}

// keyframe fade-in
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// keyframe smoothBlink
@keyframes smoothBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
